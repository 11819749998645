/**
 * @param val: Number
 * @param isCents: Boolean
 */
export const toCurrency = (val, isCents = false) =>
  new Intl.NumberFormat("en-AU", {
    style: "currency",
    currency: "AUD",
  }).format(val / (isCents ? 100 : 1));

export const redemptionCodeFormat = (code) => {
  return code.substr(2);
};
