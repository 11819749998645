// STATE =====================================================================================

const initialState = {
  token: "",
  refreshToken: "",
  isAppNotOpenedFirstTime:false
};

// MUTATIONS ===================================================================================
export const LOGIN_SESSION = "LOGIN_SESSION";
export const LOGOUT_SESSION = "LOGOUT_SESSION";
export const IS_APP_NOT_OPENED_FIRST_TIME = "IS_APP_NOT_OPENED_FIRST_TIME";

const userSessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case IS_APP_NOT_OPENED_FIRST_TIME:
      const data=action.data;
      return {...state, isAppNotOpenedFirstTime:data};
    case LOGIN_SESSION:
      const { token, refreshToken } = action;
      return {
        ...state,
        token,
        refreshToken,
      };
    case LOGOUT_SESSION:
      return {
        ...state,
        token: "",
        refreshToken: "",
      };
    default:
      return state;
  }
};

// ACTIONS =====================================================================================
export const loginSession = ({ token, refreshToken }) => ({
  type: LOGIN_SESSION,
  token,
  refreshToken
});

export const logoutSession = () => ({ type: LOGOUT_SESSION });

// COMPUTED ========================================================================================
export default userSessionReducer;
