// STATE =====================================================================================
const initialProfile = {
  firstName: "",
  lastName: "",
  mobile: "",
  work: "",
  home: "",
  status: 0,
  street2: "",
  street1: "",
  titleId: "",
  suburb: "",
  company: "",
  gender: 0,
  dateOfBirth: "",
  state: "",
  email: "",
  winePreference: "",
  wineDessert: "",
  dateJoined: 0,
  lastActivityAt: 0,
  allergies: [],
  customerGroup: "",
  winePriceRange: "",
  membershipNumber: "",
  postcode: "",
  receiveMarketing: false,
  totalPointsEarned: 0,
  totalPointsRedeemed: 0,
};

const initialState = {
  token: "",
  refreshToken: "",
  profile: initialProfile,
};

// MUTATIONS ===================================================================================
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const UPDATE_PROFILE = "UPDATE_PROFILE";

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      const { token, refreshToken } = action;
      return {
        ...state,
        token,
        refreshToken,
      };
    case LOGOUT:
      return {
        ...state,
        token: "",
        refreshToken: "",
        profile: initialProfile,
      };
    case UPDATE_PROFILE:
      const { profile } = action;
      return {
        ...state,
        profile: {
          ...state.profile,
          ...profile,
        },
      };
    default:
      return state;
  }
};

// ACTIONS =====================================================================================
export const login = ({ token, refreshToken }) => ({
  type: LOGIN,
  token,
  refreshToken,
});


export const logout = () => ({ type: LOGOUT });

export const updateProfile = (profile) => ({
  type: UPDATE_PROFILE,
  profile,
});

// COMPUTED ========================================================================================
export default userReducer;
