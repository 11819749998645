import React from "react";
import { useSelector } from "react-redux";

const ContentBody = ({ children }) => {
  const pendingEmailState = useSelector((state) => state.user.profile.pendingEmailState);

  return (
    <section className="section-container" style={{ paddingTop: pendingEmailState ? "55px" : "0" }}>
      {children}
    </section>
  );
};

export default ContentBody;
