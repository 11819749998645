import React, { useEffect, useState } from "react";
import ContentWrapper from "@/components/styling/ContentWrapper";
import styled from "styled-components";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Bookmark, Gift, MapPin } from "react-feather";
import { Link } from "react-router-dom";
import { http, httpPublic } from "@/utils/http";
import endpoints from "@/constants/endpoints";
import debug from "@/utils/debug";
import Select from "react-select";
import axios from "axios";

import { useTenant } from "@/router/TenantGateway";
import PageLoader from "@/components/widgets/PageLoader";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useGetCustomToken } from "../hooks/useGetCustomToken";

const Home = () => {
  const {
    tenantId,
    restaurantId,
    options,
    root,
    isTenant,
    isRestaurant,
    theme,
  } = useTenant();

  const [restaurants, setRestaurants] = useState([]);
  const [clientId, setClientId] = useState("");
  const [config, setConfig] = useState(null);
  const [isBookingSelectionModalOpen, openBookingSelectionModal] =
    useState(false);
  const [isWebsiteModalOpen, openWebsiteModal] = useState(false);
  const [selectedVenue, selectVenue] = useState(null);
  const [loading, setLoading] = useState(true);
  const cid = useSelector((state) => state.user.profile.id);

  const { customToken, loadingCustomToken } = useGetCustomToken();

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      try {
        const [{ data }, { data: data2 }, { data: config }] = await Promise.all(
          [
            (await http(tenantId)).get(endpoints().options.restaurants),
            (await httpPublic(tenantId)).get(endpoints().options.clientId),
            await axios.get(
              `${process.env.REACT_APP_ADMIN_URL}/configByTenant?tenantId=${tenantId}`
            ),
          ]
        );
        setRestaurants(data);
        setClientId(data2.clientId);
        setConfig(config);
      } catch (e) {
        debug(e);
      }
      setLoading(false);
    };
    loadData().then();
  }, [tenantId]);

  // open a modal if tenant, or directly the restaurant website if restaurant
  const handleBookingButton = () => {
    if (isRestaurant) {
      return openBookingWidget();
    }
    openBookingSelectionModal(true);
  };

  const openBookingWidget = () => {
    const widgetPath = config.widgetPath;

    // when "restaurant" mode, just get restaurant id from that
    const restaurantIdInner = restaurantId || selectedVenue;
    const restaurant = restaurants.find(({ id }) => id === restaurantIdInner);
    const widgetId = restaurant.widgetId;

    const widgetLink = `${widgetPath}/booking?clientId=${clientId}&restaurantId=${restaurantIdInner}&widgetId=${widgetId}&theme=default&cid=${cid}&token=${customToken}`;
    if (!widgetId) {
      toast(
        "The CRM widget has not been enabled for this restaurant.",
        {
          position: "top-right",
        }
      );
    } else {
      window.open(widgetLink, "_blank");
    }
  };

  // open a modal if tenant, or directly the restaurant website if restaurant
  const handleVisitWebsiteButton = () => {
    const { useGroupWebsite } = options;

    // options will always be for current restaurant / tenant.
    if (useGroupWebsite) {
      // tenant.useGroupWebsite or restaurant.useGroupWebsite
      let website = config.website;
      if (!website.startsWith("http")) website = `https://${website}`;
      window.open(website, "_blank");
      return;
    }

    // is this restaurant site or tenant?
    if (isRestaurant) {
      // open restaurant website, not the modal.
      const restaurant = restaurants.find(({ id }) => id === restaurantId);
      let website = restaurant.website;
      if (!website.startsWith("http")) website = `https://${website}`;
      return window.open(website, "_blank");
    }

    // select which website to open
    return openWebsiteModal(true);
  };

  // inside the modal
  const openRestaurantWebsite = () => {
    const restaurant = restaurants.find(({ id }) => id === selectedVenue);
    let website = restaurant.website;
    if (!website) return debug("No website set for restaurant!");
    if (!website.startsWith("http")) website = `https://${website}`;

    window.open(website, "_blank");
  };

  if (loading || loadingCustomToken) return <PageLoader />;

  return (
    <>
      {/* BOOKING SELECTION MODAL */}
      {isBookingSelectionModalOpen && (
        <Modal
          isOpen
          onClosed={() => openBookingSelectionModal(false)}
          toggle={() => openBookingSelectionModal(false)}
          centered
        >
          <ModalHeader toggle={() => openBookingSelectionModal(false)}>
            Choose restaurant
          </ModalHeader>
          <ModalBody>
            <Select
              options={restaurants
                .filter((r) => r.isBookingWidgetEnabled)
                .map((r) => ({ value: r.id, label: r.name }))}
              defaultValue={selectedVenue}
              onChange={(r) => selectVenue(r.value)}
            />
          </ModalBody>
          <ModalFooter>
            <B
              style={{
                backgroundColor: theme.btnColor,
                color: theme.primaryText,
              }}
              onClick={openBookingWidget}
            >
              Book online
            </B>
          </ModalFooter>
        </Modal>
      )}

      {isWebsiteModalOpen && (
        <Modal
          isOpen
          onClosed={() => openWebsiteModal(false)}
          toggle={() => openWebsiteModal(false)}
          centered
        >
          <ModalHeader toggle={() => openWebsiteModal(false)}>
            Choose restaurant
          </ModalHeader>
          <ModalBody>
            <Select
              options={restaurants
                .filter((r) => r.isBookingWidgetEnabled)
                .map((r) => ({ value: r.id, label: r.name }))}
              defaultValue={selectedVenue}
              onChange={(r) => selectVenue(r.value)}
            />
          </ModalBody>
          <ModalFooter>
            <B
              style={{
                backgroundColor: theme.headerColor,
                color: theme.primaryText,
              }}
              onClick={openRestaurantWebsite}
            >
              Visit website
            </B>
          </ModalFooter>
        </Modal>
      )}

      <ContentWrapper>
        <div className="content-heading">
          <ContentHeading>Home</ContentHeading>
        </div>
        <CenterThinContainer>
          <B
            style={{ backgroundColor: theme.btnColor, color: theme.primaryText }}
            onClick={handleBookingButton}
          >
            Book Online
          </B>

          <B tag={Link} to={`/${root}/member/bookings`}>
            My Bookings
          </B>

          {/* todo: hide website if restaurant and restaurant has no website? */}
          {(isTenant || true) && (
            <B onClick={handleVisitWebsiteButton}>Visit Website</B>
          )}

          <ButtonGrid>
            <GridB
              className="mb-2"
              style={{ width: "48%" }}
              tag={Link}
              to={`/${root}/member/offers`}
            >
              <Bookmark />
              Member Offers
            </GridB>
            <GridB
              className="mb-2"
              style={{ width: "48%" }}
              tag={Link}
              to={`/${root}/member/gift-certificates`}
            >
              <Gift />
              Gift Certificates
            </GridB>
            <GridB
              className="mb-2"
              style={{ width: "48%" }}
              tag={Link}
              to={`/${root}/restaurant-details`}
            >
              <MapPin />
              Restaurant Details
            </GridB>
          </ButtonGrid>
        </CenterThinContainer>
      </ContentWrapper>
    </>
  );
};

const B = styled(Button)`
  border: 1px solid rgb(183, 196, 220);
  width: 100%;
  margin-bottom: 10px;
`;

export const GridB = styled(Button)`
  padding: 20px 20px;
  width: 48%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid rgb(183, 196, 220);
`;

export const ButtonGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
`;

export const ContentHeading = styled.span`
  margin-left: auto;
  margin-right: auto;
  font-size: 1.3rem;
`;

export const CenterThinContainer = styled.div`
  width: 400px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
`;

export default Home;
