import React from "react";
import BottomToolTipPwa from "../widgets/BottomToolTipPwa";

const BlankLayout = ({ restaurantId, children }) => (
  <div className={[!restaurantId && "wrapper"]}>
    {/* when used as the restaurant level of wrapper, do not use any class */}
    {children}
    <BottomToolTipPwa />
  </div>
);

export default BlankLayout;
