import React from "react";
import styled from "styled-components";
import { Code } from "react-feather";
import { Button } from "reactstrap";

const DebugButton = ({ onClick, children }) => {
  if (process.env.NODE_ENV !== "development") return null;
  return (
    <DebugButtonStyled onClick={onClick} size="sm">
      {children || <Code size={10} />}
    </DebugButtonStyled>
  );
};

const DebugButtonStyled = styled(Button)`
  position: absolute;
  right: 20px;
  top: 20px;
  border-color: darkorange;
  padding: 0;
`;

export default DebugButton;
