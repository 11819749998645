import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { toggleSetting } from "../../../store/actions/actions";
import HeaderRun from "./Header.run";
import styled from "styled-components";
import Logo from "@/components/widgets/Logo";
import { useTenant } from "@/router/TenantGateway";

const Header = () => {
  const dispatch = useDispatch();
  const pendingEmailState = useSelector((state) => state.user.profile.pendingEmailState);
  // styling from context
  const {
    options: { headerColor },
  } = useTenant();
  const { r, g, b } = headerColor;
  const backgroundColor = `rgb(${r},${g},${b})`;

  useEffect(() => {
    HeaderRun();
  }, []);

  const toggleCollapsed = (e) => {
    e.preventDefault();
    dispatch(toggleSetting("isCollapsed"));
    resize();
  };

  const toggleAside = (e) => {
    e.preventDefault();
    dispatch(toggleSetting("asideToggled"));
  };

  const resize = () => {
    // all IE friendly dispatchEvent
    var evt = document.createEvent("UIEvents");
    evt.initUIEvent("resize", true, false, window, 0);
    window.dispatchEvent(evt);
    // modern dispatchEvent way
    // window.dispatchEvent(new Event('resize'));
  };

  return (
    <header className="topnavbar-wrapper">
      {/* START Top Navbar */}
      {pendingEmailState && (
        <nav id="top-message-nav" className="navbar topnavbar" style={{ backgroundColor: "orange", color: "white" }}>
          <div style={{ textAlign: "center", width: "100%", paddingTop: "8px", paddingBottom: "3px" }}>
            <h4>Your account is still unverified</h4>
            <h5>Please click the verification link sent to your email</h5>
          </div>
        </nav>
      )}

      <nav className="navbar topnavbar" style={{ backgroundColor }}>
        {/* START Left navbar */}
        <ul className="navbar-nav mr-auto flex-row">
          <li className="nav-item">
            {/* Button used to collapse the left sidebar. Only visible on tablet and desktops */}
            <a href="" className="nav-link d-none d-md-block d-lg-block d-xl-block" onClick={toggleCollapsed}>
              <em className="fas fa-bars" />
            </a>
            {/* Button to show/hide the sidebar on mobile. Visible on mobile only. */}
            <a href="" className="nav-link sidebar-toggle d-md-none" onClick={toggleAside}>
              <em className="fas fa-bars" />
            </a>
          </li>
        </ul>
        {/* START navbar header */}
        <LogoWrap>
          <Logo />
        </LogoWrap>
        {/* END navbar header */}
      </nav>
      {/* END Top Navbar */}
    </header>
  );
};

export const LogoWrap = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;

export default Header;
