// tenantId is unused for now but may be used in the future
// right now tenantId is in the http level.
// but since we have another level (restaurant) we may want to combine

const endpoints = (tenantId, restaurantId) => ({
  // functions/src/router/auth.ts
  auth: {
    login: "/auth/login/",
    refresh: "/auth/refresh/",
    recover: "/auth/recover/",
    register: "/auth/register/",
    reset: "/auth/reset/",
    quickRegister: "/auth/quick-register/",
    accountRegister: "/auth/register-account",
    profileRegister: "/auth/register-profile",
    sendEmailVerification: "/auth/generate-email-verification/",
    sendChangeEmailVerification: "/auth/generate-change-email-verification/",
    verifyEmail: "/auth/verify-email/",
    verifyChangeEmail: "/auth/verify-change-email/",
    checkEmailVerification: "/auth/check-email-verification",
    changeEmail: "/auth/change-email",
  },
  profile: "/user/profile/",
  membership: "/user/membership/",
  transaction: {
    points: "/transaction/points/", // GET points summary,
    summary: {
      store: "/transaction/summary/store/", // for dashboard
    },
    get: "/transaction/", // todo: should rename to `list`
    detail: (id) => `/transaction/${id}`, // api/@/router/crm/transactions
  },
  vouchers: "/voucher/",
  bookings: "/booking/",
  giftCertificates: "/gift-certificate/",
  getGiftCertificatePdf: (giftCertificateId) => `/gift-certificate/getGiftCertificatePdf?restaurantId=${restaurantId}&giftCertificateId=${giftCertificateId}`,
  options: {
    clientId: "/options/clientid/",
    main: "/options/" + (restaurantId ? `?restaurantId=${restaurantId}` : ""),
    terms: "/options/terms" + (restaurantId ? `?restaurantId=${restaurantId}` : ""), // GET
    profile: "/options/profile/" + (restaurantId ? `?restaurantId=${restaurantId}` : ""), // GET
    restaurants: "/options/restaurants/" + (restaurantId ? `?restaurantId=${restaurantId}` : ""), // GET
    memberOffers: "/options/memberoffers/" + (restaurantId ? `?restaurantId=${restaurantId}` : ""), // GET
    manifest: "/options/manifest" + (restaurantId ? `?restaurantId=${restaurantId}` : ""),
  },
});

export default endpoints;
