import React, { useState, useEffect } from "react";
import ContentWrapper from "../../components/styling/ContentWrapper";
import { Button, Card, CardBody, Row, Col, Collapse, Table } from "reactstrap";
import { ButtonGrid, ContentHeading } from "@/views/Home";
import moment from "moment-timezone";
import styled from "styled-components";
import { Calendar, Edit2, UserPlus, ChevronUp, ChevronDown } from "react-feather";
import { http, httpPublic } from "@/utils/http";
import endpoints from "@/constants/endpoints";
import debug from "@/utils/debug";
import axios from "axios";
import Gap from "@/components/styling/Gap";
import { useTenant } from "@/router/TenantGateway";
import PageLoader from "@/components/widgets/PageLoader";
import InnerHeader from "@/components/styling/InnerHeader";
import DebugButton from "@/components/_dev/DebugButton";
import { CollapseControl } from "@/components/widgets/CollapseControl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUtensils } from "@fortawesome/free-solid-svg-icons";
import { every } from "lodash";
import RestaurantDefaultImg from "@/assets/img/restaurant.png";

const Bookings = () => {
  const { tenantId, restaurantId, isRestaurant } = useTenant();

  // data
  const [bookings, setBookings] = useState([]);
  const [config, setConfig] = useState(null);
  const [clientId, setClientId] = useState("");

  const [loading, setLoading] = useState(false);

  // 2 sections show / hide
  const [isOpen1, setIsOpen1] = useState(true);
  const [isOpen2, setIsOpen2] = useState(false);

  const minutesOfDay = (time) => {
    return time.minutes() + time.hours() * 60;
  };
  const bookingsCurrent = bookings.filter((b) => {
    const date = Number(b.date);
    const todayDate = Number(moment().format("YYYYMMDD"));
    if (date > todayDate) return true;
    else if (date == todayDate) {
      const startTime = moment(Number(b.intervalId)).utc();
      const currentTime = moment();
      return minutesOfDay(startTime) > minutesOfDay(currentTime);
    }
    return false;
  });
  bookingsCurrent.sort((a, b) => moment(a.date, "YYYYMMDD").subtract(moment(b.date, "YYYYMMDD")));
  const bookingsPast = bookings.filter((b) => {
    const date = Number(b.date);
    const todayDate = Number(moment().format("YYYYMMDD"));
    if (date < todayDate) return true;
    else if (date == todayDate) {
      const startTime = moment(Number(b.intervalId)).utc();
      const currentTime = moment();
      return minutesOfDay(startTime) <= minutesOfDay(currentTime);
    }
    return false;
  });
  bookingsPast.sort((a, b) => moment(b.date, "YYYYMMDD").subtract(moment(a.date, "YYYYMMDD")));

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      try {
        let [{ data }, { data: data2 }, { data: config }] = await Promise.all([(await http(tenantId)).get(endpoints().bookings), (await httpPublic(tenantId)).get(endpoints().options.clientId), await axios.get(`${process.env.REACT_APP_ADMIN_URL}/configByTenant?tenantId=${tenantId}`)]);

        // if restaurant level
        if (isRestaurant) {
          data = data.filter((b) => b.restaurantId === restaurantId);
        }
        data = data.map((booking) => ({
          ...booking,
          timeStart: moment.tz(Number(booking.intervalId), booking.restaurant.zoneId).utc().format("hh:mmA"),
          timeEnd: moment
            .tz(Number(booking.intervalId) + booking.alg.duration * 60 * 1000, booking.restaurant.zoneId)
            .utc()
            .format("hh:mmA"),
        }));

        // set data
        setBookings(data);
        setClientId(data2.clientId);
        setConfig(config);
      } catch (e) {
        debug(e);
      }
      setLoading(false);
    };
    loadData().then();
  }, [tenantId]);

  const displayOrderingLink = (b) => {
    const conditions = [b.menu, b.widget, b.widget?.orderingLink];
    if (b.widget?.orderingLinkDays) {
      conditions.push(moment(b.date, "YYYYMMDD").subtract(Number(b.widget.orderingLinkDays), "days").isSameOrBefore(moment()));
    }
    return every(conditions);
  };

  const openWidget = (booking, widgetType) => {
    const widgetPath = config.widgetPath; 

    if (!widgetPath) return;
  
    const { date, restaurantId, mealId, _key } = booking;
  
    const user = booking?.customerId ? `&userId=${booking.customerId}` : "";
    const params = `date=${date}&restaurantId=${restaurantId}&mealId=${mealId}&bookingId=${_key}${user}`;
    const openUrl = `${widgetPath}/${widgetType}?clientId=${clientId}&${params}`;
    window.open(openUrl, "_blank", "location=yes,height=812,width=375,scrollbars=yes,status=yes");
  };

  const getValidGuestLength = (guests) => {
    const filteredGuests = guests.filter((g) => g.firstName && g.lastName);
    return filteredGuests.length;
  };

  const BookingSection = ({ bookings, isOpen, isPast }) => {
    return (
      <Collapse isOpen={isOpen}>
        <Row>
          {bookings.map((b) => (
            <Col key={b._key} lg={4}>
              <Card>
                <DebugButton onClick={() => debug(b)} />
                <CardBody>
                  <CenterWrap>
                    <strong style={{ color: "black" }}>{b.restaurant.name}</strong>
                    <Img src={b.img ? b.img : RestaurantDefaultImg} alt={b.restaurant.name} />
                  </CenterWrap>
                  <Gap />
                  <Table>
                    <tbody>
                      <Tr>
                        <Td>
                          <b>Booking Date:</b>
                        </Td>
                        <Td>{moment(b.date, "YYYYMMDD").format("ll")}</Td>
                      </Tr>
                      <Tr>
                        <Td>
                          <b>People:</b>
                        </Td>
                        <Td>{b.pax} People</Td>
                      </Tr>
                      <Tr>
                        <Td>
                          <b>Booking Time:</b>
                        </Td>
                        <Td>
                          {b.timeStart} - {b.timeEnd}
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>
                          <b>Menu Selection:</b>
                        </Td>
                        <Td>{[b.menu && b.menu.menuDisplayName, b.course && b.course.name].join(", ")}</Td>
                      </Tr>
                      <Tr>
                        <Td>
                          <b>Booking Ref:</b>
                        </Td>
                        <Td>{b.alg.ref}</Td>
                      </Tr>
                    </tbody>
                  </Table>
                  <Gap />
                  {!isPast && (
                    <>
                      <div className="w-100 d-flex">
                        <b style={{ marginLeft: "auto", marginRight: "auto" }}>Manage your booking anytime before your arrival</b>
                      </div>
                      <Gap />
                      <ButtonGrid>
                        <GridB className="mb-2" style={{ width: "48%" }} onClick={() => openWidget(b, "/edit-booking")}>
                          <Edit2 />
                          Edit Booking
                        </GridB>
                        {b.widget && b.widget.inviteGuests ? (
                          <GridB
                            className="mb-2"
                            style={{
                              width: "48%",
                              display: "flex",
                              flexDirection: "column",
                            }}
                            onClick={() => openWidget(b, "/guest-registration")}
                          >
                            <UserPlus />
                            Invite Guests
                          </GridB>
                        ) : null}
                        {displayOrderingLink(b) ? (
                          <GridB className="mb-2" style={{ width: "48%" }} onClick={() => openWidget(b, "/ordering/menu")}>
                            <FontAwesomeIcon icon={faUtensils} size="2x" />
                            Order Food & Drinks
                          </GridB>
                        ) : null}
                        {b.widget &&
                        b.widget.covidRegistrationModule &&
                        // only allow 1 day pre-register covid
                        moment(b.date, "YYYYMMDD").isSameOrBefore(moment().add(1, "day")) ? (
                          <GridB className="mb-2" style={{ width: "48%" }} onClick={() => openWidget(b, "/guest-registration/covid19")}>
                            <Calendar />
                            COVID-19 Registration
                          </GridB>
                        ) : null}
                      </ButtonGrid>
                    </>
                  )}
                  {b.guests && getValidGuestLength(b.guests) > 0 && (
                    <Table>
                      <tbody>
                        <Tr>
                          <Td>
                            <b>Guests</b>
                          </Td>
                          <Td />
                        </Tr>
                        {b.guests.map((g, i) => (
                          <Tr key={i}>
                            <Td>Guest {i + 1}</Td>
                            <Td>
                              {g.firstName} {g.lastName}
                            </Td>
                          </Tr>
                        ))}
                      </tbody>
                    </Table>
                  )}
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>
      </Collapse>
    );
  };

  if (loading) return <PageLoader />;

  return (
    <ContentWrapper>
      <InnerHeader>
        <ContentHeading>My Bookings</ContentHeading>
      </InnerHeader>
      <Card>
        <div
          style={{
            backgroundColor: "rgb(245,247,250)",
            margin: "15px",
            padding: "6px",
          }}
        >
          <CenterWrapWide>
            <CollapseControl onClick={() => setIsOpen1(!isOpen1)}>
              <h4>
                <strong>Upcoming Bookings</strong>
              </h4>
              <ArrowLeft>{isOpen1 ? <ChevronDown /> : <ChevronUp />}</ArrowLeft>
            </CollapseControl>
            <BookingSection bookings={bookingsCurrent} isOpen={isOpen1} />

            <CollapseControl onClick={() => setIsOpen2(!isOpen2)}>
              <h4>
                <strong>Past Bookings</strong>
              </h4>
              <ArrowLeft>{isOpen2 ? <ChevronDown /> : <ChevronUp />}</ArrowLeft>
            </CollapseControl>
            <BookingSection bookings={bookingsPast} isOpen={isOpen2} isPast />
          </CenterWrapWide>
        </div>
      </Card>
    </ContentWrapper>
  );
};

const ArrowLeft = styled.div`
  position: absolute;
  left: 20px;
  top: 10px;
`;

export const GridB = styled(Button)`
  background-color: rgb(239, 239, 239);
  padding: 20px 20px;
  width: 48%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: rgb(96, 144, 254);
  font-weight: bold;
`;

const CenterWrapWide = styled.div`
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
`;

const CenterWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Img = styled.img`
  margin-top: 20px;
  width: 70px;
  height: 70px;
  border-radius: 5px;
`;

const Tr = styled.tr`
  border: none !important;
`;
const Td = styled.td`
  border: none !important;
  padding: 3px !important;
`;

export default Bookings;
