import styled from "styled-components";

export const CollapseControl = styled.div`
  cursor: pointer;
  position: relative;
  background-color: white;
  width: 100%;
  border-top: 1px #d9d9d9 solid;
  border-bottom: 1px #d9d9d9 solid;
  justify-content: center;
  display: flex;
  padding: 10px 0;
  margin-bottom: 10px;
`;
