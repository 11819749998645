import React from "react";

// See more loading icons here:
// https://fontawesome.com/how-to-use/on-the-web/styling/animating-icons
const PageLoader = () => (
  <div className="page-loader" style={{paddingTop:"70px"}}>
    <em className="fas fa-circle-notch fa-spin fa-2x text-muted"></em>
  </div>
);

export default PageLoader;
