import React from "react";

/**
 * Wrapper element for template content
 */
const ContentWrapper = ({ unwrap = false, children }) => (
  <div className="content-wrapper">
    {unwrap ? <div className="unwrap">{children}</div> : children}
  </div>
);

export default ContentWrapper;
