import { createStore, applyMiddleware, compose } from "redux";
import middlewares from "./middlewares/middlewares";

import { combineReducers } from "redux";

import settings from "@/store/reducers/settings";
import theme from "@/store/reducers/themes";
import userReducer from "@/store/reducers/user";
import { updateTheme } from "./middlewares/themes.middleware";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import storageSession from "redux-persist/lib/storage/session";

import { persistStore, persistReducer } from "redux-persist";
import userSessionReducer from "@/store/reducers/userSession";
// expose redux in development only
const composeEnhancer =
  process.env.NODE_ENV === "development"
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    : compose;

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["settings", "themes"],
};

const userPersistConfig = {
  key: "user", // key of this reducer
  storage: storage,
  //blacklist: ["profile"],
};

const userTempPersistConfig = {
  key: "userSession", // key of this reducer
  storage: storageSession,
};

const rootReducer = combineReducers({
  settings,
  theme,
  user: persistReducer(userPersistConfig, userReducer),
  userSession: persistReducer(userTempPersistConfig, userSessionReducer),
});

export const store = createStore(
  persistReducer(persistConfig, rootReducer),
  composeEnhancer(applyMiddleware(...middlewares))
);

// Update the initial theme
updateTheme(store.getState());
export let persistor = persistStore(store);
