import React from "react";

import Header from "./components/Header";
import Sidebar from "./components/Sidebar";
import Footer from "./components/Footer";
import BottomToolTipPwa from "../widgets/BottomToolTipPwa";
import ContentBody from './components/ContentBody';

const Layout = ({ children, tenantId, restaurantId, root }) => (
  <div className={[!restaurantId && "wrapper"]}>
    {/* when used as the restaurant level of wrapper, do not use any class */}

    <Header tenantId={tenantId} />

    <Sidebar root={root} />
    <ContentBody>{children}</ContentBody>
    <Footer />
    <BottomToolTipPwa />
  </div>
);

export default Layout;
