import React, { useEffect, useState } from "react";
import ContentWrapper from "../../components/styling/ContentWrapper";
import { Button, Card, CardBody, Col, Collapse, Row, Spinner } from "reactstrap";
import Loading from "@/components/widgets/Loading";
import { ContentHeading } from "@/views/Home";
import styled from "styled-components";
import { ChevronDown, ChevronUp } from "react-feather";
import moment from "moment";
import { http } from "@/utils/http";
import debug from "@/utils/debug";
import endpoints from "@/constants/endpoints";
import Gap from "@/components/styling/Gap";
import { useTenant } from "@/router/TenantGateway";
import { CollapseControl } from "@/components/widgets/CollapseControl";
import axios from "axios";
import { redemptionCodeFormat } from "../../utils/format";
import { toCurrency } from "./../../utils/format";

const GiftCertificates = () => {
  const { tenantId, restaurantId, clientId, theme } = useTenant();
  // selector / side-load
  const [restaurants, setRestaurants] = useState([]);
  const [giftCertificates, setGiftCertificates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [config, setConfig] = useState({});
  // 2 sections show / hide
  const [isOpen1, setIsOpen1] = useState(true);
  const [isOpen2, setIsOpen2] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      try {
        let [{ data }, { data: dataRestaurants }, { data: client }] = await Promise.all([
          (await http(tenantId, clientId)).get(endpoints().giftCertificates),
          (await http(tenantId)).get(endpoints().options.restaurants),
          await axios.get(
            // eslint-disable-next-line no-undef
            `${process.env.REACT_APP_ADMIN_URL}/configByTenant?tenantId=${tenantId}`
          ),
        ]);
        // set data
        setGiftCertificates(data);
        setRestaurants(dataRestaurants);
        setConfig(client);
      } catch (e) {
        debug(e);
      }
      setLoading(false);
    };
    loadData().then();
  }, [tenantId]);

  let giftCertificatesFormatted = giftCertificates.map((g) => {
    if (!("amountRedeemed" in g)) g.amountRedeemed = 0;
    if (!("quantityRedeemed" in g)) g.quantityRedeemed = 0;
    if (!("redeemQuantity" in g)) g.redeemQuantity = 0;
    if (!("expiryDate" in g)) g.expiryDate = null;
    return {
      ...g,
    };
  });

  // FILTER, if restaurant level
  if (restaurantId) {
    giftCertificatesFormatted = giftCertificatesFormatted.filter((g) => g.restaurantId == restaurantId);
  }
  const isGCValid = (gc) => {
    const { amountRedeemed = 0, quantityRedeemed = 0, redeemQuantity = 0, amount, expiryDate = null } = gc;
    const redeemQuantityLeft = redeemQuantity - quantityRedeemed;
    const redeemAmountLeft = amount - amountRedeemed;
    if ((expiryDate && Number(expiryDate) < Number(moment().format("YYYYMMDD"))) || (redeemQuantity > 0 && redeemQuantityLeft < 1) || redeemAmountLeft < 1 || !gc.enabled) return false;
    return true;
  };

  // download Gift-certificate
  const onDownloadGC = async (giftCertificate) => {
    setDownloadLoading(true);
    try {
      const response = await (await http(tenantId)).get(endpoints(tenantId, giftCertificate.restaurantId).getGiftCertificatePdf(giftCertificate._key));
      const { emailPdf } = response.data;
      const link = document.createElement("a");
      link.innerHTML = "Download PDF file";
      link.download = "gift-certificate.pdf";
      link.href = "data:application/octet-stream;base64," + emailPdf;
      link.click();
      setDownloadLoading(false);
    } catch (e) {
      setDownloadLoading(false);
    }
  };

  const GiftCertificateSection = ({ giftCertificates, isOpen }) => (
    <Collapse isOpen={isOpen}>
      <Row>
        {giftCertificates.map((b) => (
          <Col key={b._key} lg={4}>
            <Card>
              <Img src={b.image ? b.image : ""} />
              <CardBody>
                <CenterWrap>
                  <div className="font-weight-bold mb-3" style={{ color: "black" }}>
                    Gift Certificate
                  </div>
                  <h4>{toCurrency(b.amount)}</h4>

                  <div className="font-weight-bold mb-3">{b.heading}</div>

                  <p style={{ textAlign: "center" }}>{b.message}</p>
                  <Gap />

                  <Content>
                    <p>
                      <strong>Gift Certificate Number: </strong>
                      {redemptionCodeFormat(b.voucherNumber)}
                    </p>
                    <p>
                      <strong>Amount Redeemed: </strong>
                      {toCurrency(b.amountRedeemed || 0)}
                    </p>
                    <p>
                      <strong>Amount Remaining: </strong>
                      {toCurrency(b.amount - b.amountRedeemed || 0)}
                    </p>
                    <p>
                      <strong>Expiry: </strong>
                      {b?.expiryDate ? moment(b.expiryDate, "YYYYMMDD").format("DD/MM/YYYY") : null}
                    </p>
                    <Gap />
                  </Content>
                  <p>
                    <strong>Redeemable at:</strong>
                  </p>
                  <Content>{restaurants[restaurants.findIndex((r) => r.id == b.restaurantId)].name}</Content>

                  <p className="mt-3 mb-3">
                    <a href={`${config.widgetPath}/giftcertificates/terms-of-use?clientId=${clientId}&restaurantId=${b.restaurantId}`} target="_blank" rel="noreferrer noopener">
                      Terms and Conditions
                    </a>
                  </p>
                  <Button
                    style={{
                      backgroundColor: theme.btnColor,
                      color: theme.primaryText,
                      width: "100%",
                    }}
                    disabled={downloadLoading}
                    onClick={() => onDownloadGC(b)}
                  >
                    {downloadLoading && <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />}
                    Download Gift Certificate
                  </Button>
                </CenterWrap>
                <Gap />
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
    </Collapse>
  );

  return (
    <ContentWrapper>
      <div className="content-heading">
        <ContentHeading>My Gift Certificates</ContentHeading>
      </div>
      <Card>
        <div
          style={{
            backgroundColor: "rgb(245,247,250)",
            margin: "15px",
            padding: "6px",
          }}
        >
          {loading ? (
            <Loading />
          ) : (
            <>
              <CollapseControl onClick={() => setIsOpen1(!isOpen1)}>
                <h4>
                  <strong>Gift Certificates</strong>
                </h4>
                <ArrowLeft>{isOpen1 ? <ChevronDown /> : <ChevronUp />}</ArrowLeft>
              </CollapseControl>
              <CenterWrapWide>
                <GiftCertificateSection giftCertificates={giftCertificatesFormatted.filter((g) => isGCValid(g))} isOpen={isOpen1} />
              </CenterWrapWide>
              <CollapseControl onClick={() => setIsOpen2(!isOpen2)}>
                <h4 style={{ marginLeft: 40 }}>
                  <strong>Expired and Redeemed Gift Certificates</strong>
                </h4>
                <ArrowLeft>{isOpen2 ? <ChevronDown /> : <ChevronUp />}</ArrowLeft>
              </CollapseControl>
              <CenterWrapWide>
                <GiftCertificateSection giftCertificates={giftCertificatesFormatted.filter((g) => !isGCValid(g))} isOpen={isOpen2} />
              </CenterWrapWide>
            </>
          )}
        </div>
      </Card>
    </ContentWrapper>
  );
};

const ArrowLeft = styled.div`
  position: absolute;
  left: 20px;
  top: 10px;
`;

const CenterWrapWide = styled.div`
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
`;

const CenterWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Img = styled.img`
  width: 100%;
  height: 170px;
  object-fit: cover;
`;

const Content = styled.div`
  p {
    margin-bottom: 0;
    text-align: center;
  }
`;

export default GiftCertificates;
