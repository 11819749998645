import React from "react";
import { Router } from "react-router-dom";
// App Routes
import TenantGateway from "./router/TenantGateway";
// Vendor dependencies
import "./assets/vendors";
// Application Styles
import "./assets/scss/angle/bootstrap.scss";
import "./assets/scss/angle/app.scss";
import { createBrowserHistory } from "history";
// react datetime
import "react-datetime/css/react-datetime.css";
// react phone input
import "react-phone-input-2/lib/style.css";
// toastify
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

toast.configure();

export const history = createBrowserHistory();

const App = () => {
  return (
    <Router history={history}>
      <TenantGateway />
    </Router>
  );
};

export default App;
