export const injectManifest = (manifestData) => {
  const stringManifest = JSON.stringify(manifestData);
  const blob = new Blob([stringManifest], { type: "application/json" });
  const manifestURL = URL.createObjectURL(blob);
  document
    .querySelector("#manifest-placeholder")
    .setAttribute("href", manifestURL);

  //Setting icons for Ios.
  // Todo: Need to change icons for ios about how it should be setup after testing on ios

  //Set 192 icon
  document
    .querySelector("#apple-icon")
    .setAttribute("href", manifestData.icons[0].src);

  // Set 512 icon
  document
    .querySelector("#apple-splash-screen")
    .setAttribute("href", manifestData.icons[1].src);
};
