import React from "react";

const NotFound = () => (
  <div className="abs-center wd-xl">
    <div className="text-center mb-4">
      <div className="text-lg mb-3">404</div>
      <p className="lead m-0">We couldn't find this page.</p>
      <p>The page you are looking for does not exist.</p>
    </div>
    <div className="p-3 text-center">
      <span className="mr-2">&copy;</span>
      <span>{new Date().getFullYear()}</span>
      <span className="mx-2">-</span>
      <span>ResButler Pty Ltd</span>
    </div>
  </div>
);

export default NotFound;
