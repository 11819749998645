/* eslint-disable no-undef */
import axios from "axios";
import { store } from "@/store/store";
import endpoints from "@/constants/endpoints";
import { history } from "@/App";
import { login, logout } from "@/store/reducers/user";
import { loginSession, logoutSession } from "@/store/reducers/userSession";

/**
 * A client for public (no JWT routes)
 * @param tenantId
 * @returns {AxiosInstance}
 */
export const httpPublic = async (tenantId) => {
  if (!tenantId) throw Error("Must provide a tenantId to instantiate this http client.");
  let apiUrl;
  try {
    const {
      data: { resbutlerApis, client },
    } = await axios.get(`${process.env.REACT_APP_ADMIN_URL}/configByTenant?tenantId=${tenantId}`);
    if (process.env.NODE_ENV === "development") apiUrl = "http://127.0.0.1:5001/staging-60983/us-central1/crm/";
    else apiUrl = `${resbutlerApis}/crm/`;
    return axios.create({
      baseURL: `${apiUrl}/`, // already namespaced
      headers: {
        "Content-Type": "application/json",
        "client-id": client,
      },
    });
  } catch (e) {
    console.log(e);
  }
};

/*
 * A client for private routes, require JWT
 * a special namespaced / multi-tenancy http client
 */
export const http = async (tenantId) => {
  if (!tenantId) throw Error("Must provide a tenantId to instantiate this http client.");
  let apiUrl;

  try {
    const {
      data: { resbutlerApis, client },
    } = await axios.get(`${process.env.REACT_APP_ADMIN_URL}/configByTenant?tenantId=${tenantId}`);
    if (process.env.NODE_ENV === "development") apiUrl = "http://127.0.0.1:5001/staging-60983/us-central1/crm/";
    else apiUrl = `${resbutlerApis}/crm/`;

    let headerData = {};

    headerData = {
      baseURL: `${apiUrl}`, // already namespaced
      // timeout: 5e3,
      headers: {
        "Content-Type": "application/json",
        "client-id": client,
      },
    };

    const httpClient = axios.create(headerData);

    const tokenTemp = store.getState().userSession.token;
    const refreshTokenTemp = store.getState().userSession.refreshToken;
    const tokenPersist = store.getState().user.token;
    const refreshTokenPersist = store.getState().user.refreshToken;
    const tokenToUse = tokenTemp || tokenPersist;
    const refreshTokenToUse = refreshTokenTemp || refreshTokenPersist;

    /*
   get appropriate token based on persistence
   see whether token is temporary or permanent.
  */
    const remember = tokenPersist === tokenToUse;

    // request interceptor that will inject the token
    httpClient.interceptors.request.use(
      (config) => {
        // Vue.prototype.$Progress.start()
        // make a copy of original request
        const originalRequest = config;

        // Update Auth Header with JWT
        // const token = store.getState().user.token
        if (tokenToUse) {
          originalRequest.headers.Authorization = `Bearer ${tokenToUse}`;
        }
        return originalRequest;
      },
      (err) => {
        // Vue.prototype.$Progress.fail()
        return Promise.reject(err);
      }
    );

    // interceptor that handles jwt-refresh
    httpClient.interceptors.response.use(
      (response) => {
        // Vue.prototype.$Progress.finish()
        return response;
      },
      (error) => {
        // Vue.prototype.$Progress.start()
        if (error.response.status === 403) {
          // a protected route is accessed but the user doesnt have auth status.
          store.dispatch(logout());
          store.dispatch(logoutSession());
          history.push(`/${tenantId}`);
          return Promise.reject(error);
        }
        if (error.response.status !== 401) {
          // if the error is not due to refreshing error, immediately reject the promise.
          return Promise.reject(error);
        }
        // if the error is token error, try refreshing it and try again.
        return httpPublic(tenantId)
          .post(endpoints().auth.refresh, { refreshTokenToUse })
          .then((res) => {
            const { data } = res;
            const { token, refreshToken } = data;

            // refresh token
            if (remember) {
              store.dispatch(login({ token, refreshToken }));
            } else {
              store.dispatch(loginSession({ token, refreshToken }));
            }

            // New request with new token
            const config = error.config;
            config.headers.Authorization = `Bearer ${token}`;

            return new Promise((resolve, reject) => {
              axios
                .request(config)
                .then((response) => {
                  // Vue.prototype.$Progress.finish()
                  resolve(response);
                })
                .catch((error) => {
                  // Vue.prototype.$Progress.fail()
                  reject(error);
                });
            });
          })
          .catch((error) => {
            // logout due to token cannot be refreshed
            store.dispatch(logout());
            store.dispatch(logoutSession());
            history.push(`/${tenantId}`);
            return Promise.reject(error);
            // Vue.prototype.$Progress.fail()
          });
      }
    );
    return httpClient;
  } catch (e) {
    console.log(e);
  }
};

export default http;
