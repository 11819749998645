// icon references
// https://iconify.design/icon-sets/simple-line-icons/
import { store } from "@/store/store";
import { logout } from "@/store/reducers/user";
import { history } from "@/App";
import { logoutSession } from "@/store/reducers/userSession";
import { Bookmark, Calendar, Edit, Gift, Home, List, LogOut, Mail, MapPin, Percent, Star, User } from "react-feather";

const menu = (root) => [
  {
    name: "Home",
    icon: Home,
    path: `/${root}/home`,
  },
  {
    name: "My Bookings",
    icon: Calendar,
    path: `/${root}/member/bookings`,
  },
  {
    name: "Account",
    icon: User,
    path: `/${root}/member/account`,
  },
  {
    name: "Member Offers",
    icon: Bookmark,
    path: `/${root}/member/offers`,
  },
  {
    name: "Vouchers",
    icon: Percent,
    path: `/${root}/member/vouchers`,
  },
  {
    name: "Membership",
    icon: Star,
    path: `/${root}/member/your-membership`,
  },
  // {
  //   name: "Retail",
  //   icon: ShoppingBag,
  //   path: `/${root}/restaurant-details`,
  // },
  // {
  //   name: "Takeaway",
  //   icon: Archive,
  //   path: `/${root}/restaurant-details`,
  // },
  {
    name: "Gift Certificates",
    icon: Gift,
    path: `/${root}/member/gift-certificates`,
  },
  {
    name: "Restaurant Details",
    icon: MapPin,
    path: `/${root}/restaurant-details`,
  },
  {
    name: "Edit Profile",
    icon: Edit,
    path: `/${root}/member/settings/profile`,
  },
  {
    name: "Edit Email",
    icon: Mail,
    path: `/${root}/member/settings/change-email`,
  },
  {
    name: "Transactions",
    icon: List,
    path: `/${root}/member/transactions`,
  },
  {
    name: "Log out",
    icon: LogOut,
    action: () => {
      store.dispatch(logout());
      store.dispatch(logoutSession());
      history.push(`/${root}`);
    },
  },
];

export default menu;
