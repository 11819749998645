/* eslint-disable no-undef */
import React from "react";

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <footer className="footer-container">
      <span>&copy; {year} - WizButler Pty Ltd.</span> {process.env.NODE_ENV === "development" && <a href={window.location.href.replace(window.location.hostname, "staging.resbutlercrm.com").replace(`:${location.port}`, "")}>Go to production</a>}
    </footer>
  );
};

export default Footer;
