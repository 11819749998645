import { useRouteMatch } from "react-router-dom";
import React, { createContext, useContext, useEffect, useState } from "react";
import NotFound from "@/views/error/NotFound";
import http, { httpPublic } from "@/utils/http";
import endpoints from "@/constants/endpoints";
import debug from "@/utils/debug";
import PageLoader from "@/components/widgets/PageLoader";
import TenantRoutes from "@/router/TenantRoutes";
import buildRoot from "@/router/utils";
import { isLight } from "@/utils/color";

// Tenant context to be used by all sub-tree subsequent components
export const tenantInitialState = {
  tenantId: null, // only local
  clientId: null, // only local
  restaurantId: null, // only local

  root: "", // build root (either /gpo or /gpo/restaurants/:rId)

  options: {
    name: "",
    logo: "",
    featureImage: "",
    highlightBtnColor: {},
    headerColor: {},
  },
  theme: {},

  loading: false,
};

export const TenantContext = createContext(tenantInitialState);

export const useTenant = () => useContext(TenantContext);

/**
 * [Multi-tenancy router]
 *
 * Handles the root-level splitting for multi-tenancy purpose.
 * Injects the tenantId (namespace) as a top level prop
 */
const TenantGateway = () => {
  // local states
  const [options, setOptions] = useState(tenantInitialState.options);
  const [clientId, setClientId] = useState(null);
  const [loading, setLoading] = useState(false);
  // match
  let match = useRouteMatch("/:tenantId");
  const tenantId = match ? match.params.tenantId : null;

  useEffect(() => {
    setLoading(true); // need to reload

    if (clientId) {
      setLoading(false);
      return;
    }

    const loadTenant = async () => {
      setClientId(null);
      try {
        const [{ data }, { data: dataOptions }] = await Promise.all([
          (await httpPublic(tenantId)).get(endpoints().options.clientId), // verify tenantId
          (await http(tenantId)).get(endpoints().options.main), // get options
        ]);

        if (data.clientId) setClientId(data.clientId);

        if (dataOptions) {
          const { name = "", logo = "", featureImage = "", highlightBtnColor = {}, headerColor = {} } = dataOptions;
          setOptions({
            name,
            logo,
            featureImage,
            highlightBtnColor,
            headerColor,
          });
        }
      } catch (e) {
        debug(e);
      }
      setLoading(false);
    };
    loadTenant().then();
  }, [tenantId]);

  if (loading) return <PageLoader />;

  // !match means no tenantId is passed
  if (!match || !clientId) {
    debug("No tenantId passed to route, or failed to verify tenantId");
    return <NotFound />;
  }

  const theme = {
    headerColor: `rgb(${options.headerColor.r},${options.headerColor.g},${options.headerColor.b})`,
    primaryText: isLight(`rgb(${options.highlightBtnColor.r},${options.highlightBtnColor.g},${options.highlightBtnColor.b})`) ? "black" : "white",
    btnColor: `rgb(${options.highlightBtnColor.r},${options.highlightBtnColor.g},${options.highlightBtnColor.b})`,
  };

  // successfully matched
  return (
    <TenantContext.Provider
      value={{
        clientId,
        tenantId,
        options,
        loading,
        root: buildRoot(tenantId),
        isTenant: true,
        isRestaurant: false,
        theme,
      }}
    >
      <TenantRoutes />
    </TenantContext.Provider>
  );
};

export default TenantGateway;
