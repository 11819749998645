import React from "react";
import Loading from "@/components/widgets/Loading";
import { useTenant } from "@/router/TenantGateway";

const Logo = () => {
  const {
    options: { logo, name },
    optionsLoading,
  } = useTenant();
  return optionsLoading ? <Loading /> : <img className="img-fluid" src={logo} alt={name} height={40} style={{ maxHeight: "40px" }} />;
};

export default Logo;
