import { Redirect, Route } from "react-router-dom";
import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";

/**
 * [login required route wrapper]
 *
 * A wrapper for <Route> that redirects to the login
 * screen if you're not yet authenticated.
 */
const PrivateRoute = ({ children, redirect, component, path, ...rest }) => {
  const location = useLocation();
  const isAuthenticated = useSelector(
    (state) => !!state.user.token || !!state.userSession.token
  );
  const isCrmUser=useSelector((state)=>state.user.profile.isCrmUser);
  if (!isAuthenticated || !isCrmUser) {
    return (
      <Redirect
        to={{
          pathname: `/${redirect}`,
          state: { from: location },
        }}
      />
    );
  }
 
  return <Route path={path} {...rest} render={() => component() || children} />;
};

/**
 * Inject authentication status to top level PrivateRoute for redirection handling.
 */
export default PrivateRoute;
