import React, { lazy, Suspense } from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";

/* loader component for Suspense*/
import PageLoader from "../components/widgets/PageLoader";

import Layout from "../components/Layout/Layout";
import BlankLayout from "../components/Layout/BlankLayout";
import PrivateRoute from "@/router/PrivateRoute";
import Home from "@/views/Home";
import Bookings from "@/views/my/Bookings";
import GiftCertificates from "@/views/my/GiftCertificates";
import { useLocation } from "react-router";
import RestaurantGateway from "@/router/RestaurantGateway";
import buildRoot from "@/router/utils";
import { useTenant } from "@/router/TenantGateway";
import { injectManifest } from "../utils/injectManifest";
import http from "@/utils/http";
import endpoints from "@/constants/endpoints";

// components
const Login = lazy(() => import("../views/auth/Login"));
//const Register = lazy(() => import("../views/auth/Register"));
const QuickRegister = lazy(() => import("../views/auth/QuickRegister"));
const ProfileRegistration = lazy(() => import("../views/auth/ProfileRegistration"));
const AccountRegistration = lazy(() => import("../views/auth/AccountRegistration"));
const EmailVerification = lazy(() => import("../views/auth/email-verification/EmailVerification"));

// const RegisterNew = lazy(() => import('../views/auth/RegisterNew'));
const Recover = lazy(() => import("../views/auth/Recover"));
const Terms = lazy(() => import("../views/auth/Terms"));
const Transactions = lazy(() => import("../views/my/Transactions"));
const Invoice = lazy(() => import("../views/my/Invoice"));
const MemberOffers = lazy(() => import("@/views/informational/MemberOffers/MemberOffers"));
const Account = lazy(() => import("../views/my/Account"));
const Profile = lazy(() => import("@/views/my/Profile"));
const ChangeEmail = lazy(() => import("@/views/my/ChangeEmail"));

const Membership = lazy(() => import("@/views/informational/MemberOffers/MembershipTerms"));
const Vouchers = lazy(() => import("@/views/my/Vouchers"));
const Restaurants = lazy(() => import("@/views/informational/Restaurants/ContactDetails"));

/**
 * [Main router at tenant level]
 */
const TenantRoutes = () => {
  const { tenantId } = useTenant();
  const location = useLocation();
  const currentKey = location.pathname.split("/")[1] || "/";
  const timeout = { enter: 500, exit: 500 };
  const { url } = useRouteMatch("/:tenantId");

  /* Used to render a lazy component with react-router */
  const root = buildRoot(tenantId); // === /tenantId
  const lz = (Tag) => (props) => <Tag {...props} root={root} />;

  const animationName = "rag-fadeIn"; //  'rag-fadeInRight',  'rag-fadeInLeft'

  /**
   * List of routes that uses the page layout
   * listed here to Switch between layouts
   * depending on the current pathname
   */
  const pagesWithoutLayout = ["", "/", "/register-new", "/quick-register", "/register", "/recover", "/terms", "/email-verification", "/profile-register"];

  const strippedTenantId = location.pathname.replace(url, "");
  const isRestaurantUrl = strippedTenantId.startsWith("/restaurants/");
  const injectGeneralManifest = async () => {
    const { data: manifest } = await (await http(tenantId)).get(endpoints(tenantId).options.manifest);
    injectManifest(manifest);
  };
  if (!isRestaurantUrl) injectGeneralManifest();

  if (pagesWithoutLayout.includes(strippedTenantId) || isRestaurantUrl) {
    return (
      // Page Layout component wrapper
      <BlankLayout tenantId={tenantId}>
        <Suspense fallback={<PageLoader />}>
          <Switch location={location}>
            {/* nest another level of restaurant */}
            <Route path={`/${tenantId}/restaurants/:restaurantId`}>
              <RestaurantGateway tenantId={tenantId} />
            </Route>

            {/* See full project for reference */}
            <Route path={`/${tenantId}/register`} component={lz(AccountRegistration)} />
            <Route path={`/${tenantId}/profile-register`} component={lz(ProfileRegistration)} />
            <Route path={`/${tenantId}/quick-register`} component={lz(QuickRegister)} />
            {/*<Route path={`/${tenantId}/register-new`} component={lz(RegisterNew)}/>*/}
            <Route path={`/${tenantId}/recover`} component={lz(Recover)} />
            <Route path={`/${tenantId}/email-verification`} component={lz(EmailVerification)} />
            <Route path={`/${tenantId}/terms`} component={lz(Terms)} />
            <Route path={`/${tenantId}`} component={lz(Login)} />
          </Switch>
        </Suspense>
      </BlankLayout>
    );
  } else {
    return (
      // Layout component wrapper
      <Layout tenantId={tenantId} root={root}>
        <TransitionGroup>
          <CSSTransition key={currentKey} timeout={timeout} classNames={animationName} exit={false}>
            <div>
              <Suspense fallback={<PageLoader />}>
                <Switch location={location}>
                  <PrivateRoute redirect={root} path={`/${root}/home`} component={lz(Home)} />
                  <PrivateRoute redirect={root} path={`/${root}/member/bookings`} component={lz(Bookings)} />
                  <PrivateRoute redirect={root} path={`/${root}/member/gift-certificates`} component={lz(GiftCertificates)} />
                  <PrivateRoute redirect={root} path={`/${root}/member/account`} component={lz(Account)} />
                  <PrivateRoute redirect={root} path={`/${root}/member/transactions/invoice/:id`} component={lz(Invoice)} />
                  <PrivateRoute redirect={root} path={`/${root}/member/transactions`} component={lz(Transactions)} />
                  <PrivateRoute redirect={root} path={`/${root}/member/offers`} component={lz(MemberOffers)} />
                  <PrivateRoute redirect={root} path={`/${root}/member/vouchers`} component={lz(Vouchers)} />
                  <PrivateRoute redirect={root} path={`/${root}/member/settings/profile`} component={lz(Profile)} />
                  <PrivateRoute redirect={root} path={`/${root}/member/settings/change-email`} component={lz(ChangeEmail)} />
                  <PrivateRoute redirect={root} path={`/${root}/member/your-membership`} component={lz(Membership)} />
                  <PrivateRoute redirect={root} path={`/${root}/restaurant-details`} component={lz(Restaurants)} />

                  <Redirect to={`/${root}/home`} />
                </Switch>
              </Suspense>
            </div>
          </CSSTransition>
        </TransitionGroup>
      </Layout>
    );
  }
};

export default TenantRoutes;
