import React, { useEffect } from "react";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IS_APP_NOT_OPENED_FIRST_TIME } from "../../store/reducers/userSession";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";

//Component for rendering install prompt for PWA
const BottomToolTipPwa = () => {
  const isAppNotOpenedFirstTime = useSelector((state) => state.userSession.isAppNotOpenedFirstTime);
  const dispatch = useDispatch();

  useEffect(() => {
    //Check for Redux whether app isopened for first time.
    if (!isAppNotOpenedFirstTime) {
      // Checks if should display install popup notification:
      if (isIos() && !isInStandaloneMode()) {
        //Display for IOS
        toast(addToHomeScreenToast, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 8000,
          hideProgressBar: true,
          closeOnClick: true,
        });
        dispatch({ type: IS_APP_NOT_OPENED_FIRST_TIME, data: true });
      }
    }
  }, []);

  const addToHomeScreenToast = () => {
    return (
      <div>
        <FontAwesomeIcon icon={faPlus} /> Install this webapp on your phone: Tap <img src="/img/ios-share.png" /> and then add to Homescreen
      </div>
    );
  };
  //Check if app is already on standalone mode
  const isInStandaloneMode = () => {
    return "standalone" in window.navigator && window.navigator.standalone;
  };

  //Check if device is IOS
  const isIos = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    // To do :Need to change the testing param to come from dynamically.
    // Testing the which device web app is opened from.
    return /iphone|ipad|ipod|macintosh/.test(userAgent);
  };
  return "";
};

export default BottomToolTipPwa;
