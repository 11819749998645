import axios from "axios";
import { store } from "../store/store";
import { useTenant } from "../router/TenantGateway";
import debug from "../utils/debug";
import { useEffect, useState } from "react";

export const fetchConfigByTenant = async (tenantId) => {
  const {
    data: { resbutlerApis, client },
  } = await axios.get(`${process.env.REACT_APP_ADMIN_URL}/configByTenant?tenantId=${tenantId}`);
  return { resbutlerApis, client };
};

export const useGetCustomToken = () => {
  const [customToken, setCustomToken] = useState(null);
  const [loadingCustomToken, setLoadingCustomToken] = useState(null);
  const { tenantId } = useTenant();

  const tokenTemp = store.getState().userSession.token;
  const tokenPersist = store.getState().user.token;
  const token = tokenTemp || tokenPersist;

  useEffect(() => {
    const loadData = async () => {
      setLoadingCustomToken(true);
      const { resbutlerApis, client } = await fetchConfigByTenant(tenantId);
      try {
        const res = await axios.get(`${resbutlerApis}/auth/getCustomToken`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "client-id": client,
          },
        });
        setCustomToken(res.data);
      } catch (e) {
        debug(e);
      } finally {
        setLoadingCustomToken(false);
      }
    };
    loadData().then();
  }, []);

  return { customToken, loadingCustomToken };
};
